<template>
    <div>
        <a-row :gutter="20">
            <a-col :span="24">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`company_name`) ? 'filled' : ''
                        "
                    >
                        Razão social
                    </label>

                    <a-input
                        class="travel-input"
                        placeholder="Razão social"
                        v-decorator="[
                            `company_name`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`trading_name`) ? 'filled' : ''
                        "
                    >
                        Nome Fantasia
                    </label>

                    <a-input
                        class="travel-input"
                        placeholder="Nome Fantasia"
                        v-decorator="[
                            `trading_name`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label :class="form.getFieldValue(`cnpj`) ? 'filled' : ''">
                        CNPJ
                    </label>

                    <a-input
                        class="travel-input"
                        placeholder="CNPJ"
                        v-mask="'##.###.###/####-##'"
                        v-decorator="[
                            `cnpj`,
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="travel-input-outer  travel-multiselector">
                    <label
                        :class="
                            form.getFieldValue(`product_supplier_id`)
                                ? 'filled'
                                : ''
                        "
                    >
                        Sub-Fornecedor
                    </label>

                    <a-select
                        class="travel-input"
                        show-search
                        :allow-clear="true"
                        mode="multiple"
                        optionFilterProp="txt"
                        placeholder="Selecione um sub fornecedor"
                        v-decorator="[
                            `product_supplier_ids`,
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of productSupplier.list"
                            :key="index"
                            :value="item.id"
                        >
                            {{ item.id }} -
                            {{ item.trading_name.toUpperCase() }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="travel-input-outer travel-multiselector">
                    <label
                        :class="form.getFieldValue(`category`) ? 'filled' : ''"
                    >
                        Categoria
                    </label>

                    <a-select
                        class="travel-input"
                        placeholder="Categoria"
                        mode="multiple"
                        optionFilterProp="txt"
                        v-decorator="[
                            `category`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                        style="width: 100%"
                    >
                        <a-select-option value="Consolidadora">
                            Consolidadora
                        </a-select-option>
                        <a-select-option value="Hotel">
                            Hotel
                        </a-select-option>
                        <a-select-option value="Operadora">
                            Operadora
                        </a-select-option>
                        <a-select-option value="Resort">
                            Resort
                        </a-select-option>
                        <a-select-option value="Serviços">
                            Serviços
                        </a-select-option>
                        <a-select-option value="Transfer">
                            Transfer
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <div class="box">
                <AddressData
                    :requiredFields="{
                        cep: false,
                        location_neighborhood: false,
                        location_state: false,
                        location_street: false,
                    }"
                    :form="form"
                />
            </div>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`company_id`) ? 'filled' : ''
                        "
                    >
                        Empresa
                    </label>

                    <a-select
                        class="travel-input"
                        placeholder="Selecione uma empresa"
                        optionFilterProp="txt"
                        v-decorator="[
                            `company_id`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                        show-search
                        style="width: 100%"
                    >
                        <a-select-option
                            v-for="(item, index) of companiesList"
                            :key="index"
                            :value="item.id"
                            :txt="item.trading_name"
                        >
                            {{ item.id }} - {{ item.trading_name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="form.getFieldValue(`status`) ? 'filled' : ''"
                    >
                        Status
                    </label>

                    <a-select
                        class="travel-input"
                        placeholder="Status"
                        optionFilterProp="txt"
                        v-decorator="[
                            `status`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]"
                        style="width: 100%"
                    >
                        <a-select-option value="Ativo">
                            Ativo
                        </a-select-option>
                        <a-select-option value="Desativado">
                            Desativado
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="form.getFieldValue(`website`) ? 'filled' : ''"
                    >
                        Website
                    </label>

                    <a-input
                        class="travel-input"
                        placeholder="Website"
                        v-decorator="[`website`]"
                    >
                        <a-icon slot="prefix" type="field-svg" />
                    </a-input>
                </a-form-item>
            </a-col>

            <a-col :span="24">
                <a-form-item class="mb-30 travel-radio">
                    <label
                        class="
                           filled
                        "
                    >
                        Tipo de pagamento
                    </label>
                    <a-radio-group
                        v-decorator="[
                            'invoice_type',
                            {
                                rules: [
                                    {
                                        required: false,
                                        message:
                                            'Selecione um tipo de pagamento.',
                                    },
                                ],
                            },
                        ]"
                        :options="invoices"/></a-form-item
            ></a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`invoice_closing_type`)
                                ? 'filled'
                                : 'filled'
                        "
                    >
                        Faturamento (Vencimento)
                    </label>

                    <a-select
                        class="travel-input select"
                        placeholder="Faturamento (Vencimento)"
                        v-decorator="[
                            `invoice_closing_type`,
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of invoiceTypes"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.value }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col
                v-if="
                    form.getFieldValue(`invoice_closing_type`) ==
                        'Diferenciado (Dia útil/Vencimento)'
                "
                :span="12"
            >
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`invoice_closing_day`)
                                ? 'filled'
                                : ''
                        "
                    >
                        Dia do Vencimento
                    </label>

                    <a-select
                        class="travel-input select"
                        placeholder="  Dia do Vencimento"
                        v-decorator="[
                            `invoice_closing_day`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of days"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`invoice_payment_type`)
                                ? 'filled'
                                : 'filled'
                        "
                    >
                        Faturamento (Fechamento)
                    </label>

                    <a-select
                        class="travel-input select"
                        placeholder="Fechamento"
                        v-decorator="[
                            `invoice_payment_type`,
                            {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of invoiceTypes"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.value }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col
                v-if="
                    form.getFieldValue(`invoice_payment_type`) ==
                        'Diferenciado (Dia útil/Vencimento)'
                "
                :span="12"
            >
                <a-form-item class="travel-input-outer">
                    <label
                        :class="
                            form.getFieldValue(`invoice_payment_day`)
                                ? 'filled'
                                : ''
                        "
                    >
                        Dia do Fechamento
                    </label>

                    <a-select
                        class="travel-input select"
                        placeholder="Dia do Fechamento"
                        v-decorator="[
                            `invoice_payment_day`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório.',
                                    },
                                ],
                            },
                        ]"
                    >
                        <a-select-option
                            v-for="(item, index) of days"
                            :key="index"
                            :value="item"
                        >
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>

            <a-col v-if="edit == true" class="mb-20" :span="24">
                <FileUpload
                    :folder="`public/product-supplier/${productSupplierId}`"
                    :multiple="false"
                    :deletable="true"
                    :sortable="false"
                    :meta="false"
                    :theme="'default'"
                    :maxSize="'10MB'"
                    :accept="'image/*'"
                    :maxFiles="1"
                    :text="'Logo do fornecedor'"
                    :userID="true"
                    :userFolder="false"
                    :url="form.getFieldValue(`logo`)"
                    :id="productSupplierId"
                    @fileURL="fileURL"
                />
                <a-form-item style="display: none">
                    <a-input
                        placeholder="LOGO"
                        v-decorator="[`logo`]"
                        style="text-transform: none"
                    >
                    </a-input>
                </a-form-item>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import AddressData from "../../general/AddressData.vue";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import FileUpload from "../../atoms/FileUpload.vue";

export default {
    props: {
        edit: Boolean,
        productSupplierId: Number,
        form: Object,
    },
    mixins: [productSupplierMixins],
    components: { AddressData, FileUpload },
    data() {
        return {
            //form: this.$form.createForm(this),
            loadingCreateProductSupplier: false,
            productSupplierList: [],
            companiesList: [],
            days: [
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
            ],
            invoices: [
                { label: "Pré Pagamento", value: "Pré Pagamento" },
                { label: "Pós Pagamento", value: "Pós Pagamento" },
                { label: "Nenhum", value: "Nenhum" },
            ],
            invoiceTypes: [
                {
                    label: "Semanal",
                    value: "Semanal",
                },
                {
                    label: "Quinzenal",
                    value: "Quinzenal",
                },
                {
                    label: "Mensal",
                    value: "Mensal",
                },
                {
                    label: "Diferenciado (Dia útil/Vencimento)",
                    value: "Diferenciado (Dia útil/Vencimento)",
                },
            ],
        };
    },
    mounted() {
        this.productSupplier.pagination.perPage = 300;
        this.getProductSuppliers();

        this.$http
            .get(`/company/list?page=1&per_page=100`)
            .then(({ data }) => {
                data;
                this.companiesList = data.data;
            })
            .catch(({ response }) => {
                response;
            });

        setTimeout(() => {
            // this.form.setFieldsValue({
            //     user_id: "1",
            //     cnpj: "55.555.555/5555-55",
            //     company_name: "FORNECEDOR LTDA",
            //     trading_name: "FORNECEDOR",
            //     website: "https://fornecedorltda.com.br",
            //     category: JSON.parse('["Hotel","Operadora"]'),
            //     cep: "35400-000",
            //     location_street: "Rua Jose Joao",
            //     location_number: "178",
            //     location_complement: "Casa",
            //     location_neighborhood: "Saramenha",
            //     location_city: "São Paulo",
            //     location_state: "SP",
            //     contacts: "[]",
            //     phones: "[]",
            //     emails: "[]",
            //     bank_accounts: "[]",
            //     invoice_closing_type: "Semanal",
            //     invoice_payment_type: "Mensal",
            //     status: "Ativo",
            // });
        }, 300);
    },
    methods: {
        fileURL(url) {
            this.form.setFieldsValue({
                [`logo`]: url,
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                values.user_id = this.$store.state.userData.id;
                values.category = JSON.stringify(values.category);
                values.product_supplier_ids = JSON.stringify(
                    values.product_supplier_ids
                );

   
                if (!err) {
                    this.loadingCreateProductSupplier = true;

                    this.$http
                        .post("/product-supplier/create", values)
                        .then(({ data }) => {
                            this.$message.success(data.message);
                            this.loadingCreateProductSupplier = false;
                            this.$emit("listProductSuppliers", true);
                        })
                        .catch(({ response }) => {
                            this.$message.error(response.data.message);
                            this.loadingCreateProductSupplier = false;
                        });
                }
            });
        },
    },
};
</script>
